import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../UI/Spinner";
import Navbar from "../navbar/Navbar";
import { getPatientSerices } from "../../firebase/firebase.selfpatient";

const SelfService = ({
  data,
  rawHistory,
  patientId,
  praxisName,
  showMoreStatus,
  view,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [historyToUpdate, setHistoryToUpdate] = useState(null);
  const [historyToDelete, setHistoryToDelete] = useState(null);

  const user = useSelector((state) => state.user?.userDetails);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const getAllRecords = async () => {
      try {
        setIsLoading(true);
        const records = await getPatientSerices(user?.patientRecordId);
        setHistory(records);
        setIsLoading(false);
        console.log(records);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    if (user) getAllRecords();
  }, [user]);

  //   const updateHistory = (selectedHistory) => {
  //     setOpenModal(true);
  //     setModalTitle("Editar");
  //     setHistoryToUpdate({
  //       userId: data.id,
  //       selectedHistory: selectedHistory,
  //       historyStateSetter: setHistory,
  //       practorId: user.id,
  //       practorName: `${user.name} ${user.lastname}`,
  //       praxisId: user.praxisId,
  //       praxisName: praxisName,
  //       allRecords: history,
  //     });
  //   };

  //   const addNewHistory = () => {
  //     setOpenModal(true);
  //     setModalTitle("Agregar");
  //     setHistoryToUpdate({
  //       userId: data.id,
  //       selectedHistory: 0,
  //       historyStateSetter: setHistory,
  //       modalState: setOpenModal,
  //       practorId: user.id,
  //       practorName: `${user.name} ${user.lastname}`,
  //       praxisId: user.praxisId,
  //       praxisName: praxisName,
  //     });
  //   };

  //   const deleteHistory = (selectedHistory) => {
  //     setOpenDeleteModal(true);
  //     setHistoryToDelete({
  //       userId: data.id,
  //       selectedHistory: selectedHistory,
  //       historyStateSetter: setHistory,
  //     });
  //   };

  return (
    <Navbar>
      <>
        <main
          className="flex flex-wrap bg-slate-800 h-full"
          style={{ justifyContent: "center" }}
        >
          <div className="mx-auto w-full">
            <div className="w-full bg-white rounded-t-lg h-full">
              <div className="flex"></div>
              <div className="flex">
                {/* <div className="bg-white rounded-t-lg w-full max-h-[91vh]"> */}
                <div className="m-5">
                  <div
                    className="flex flex-wrap md:mx-3 pb-5"
                    style={{ justifyContent: "center" }}
                  >
                    <div className="w-full max-w-full md:px-3">
                      <div className="px-4 pb-0 mb-0 border-b-0 rounded-t-2xl flex align-between justify-between items-center">
                        <h6 className="mb-0 text-black">Servicios y pagos</h6>
                      </div>
                      <div
                        className={`flex-auto md:p-4 pt-6 h-[80vh] overflow-scroll`}
                      >
                        <ul className="flex flex-col pl-0 mb-0 rounded-lg">
                          {history !== null ? (
                            history && history.length > 0 ? (
                              history.map((e, i) => {
                                let totalPayments = e?.payments?.reduce(
                                  (sum, payment) => sum + (payment.amount || 0),
                                  0
                                );
                                return (
                                  <>
                                    <li
                                      className="relative flex justify-between p-6 mb-2 border-0 rounded-t-inherit rounded-xl bg-gray-100 bg-slate-850"
                                      key={e?.id + i}
                                      onClick={() =>
                                        setShowMore(
                                          showMore === null ? e?.id : null
                                        )
                                      }
                                    >
                                      <div className="flex flex-col w-full">
                                        <div className="flex flex-col">
                                          <div>
                                            <span className="mb-1 text-xs flex items-center justify-between leading-tight text-black/80">
                                              <div className="m-1">
                                                <span className="font-light text-xs bg-slate-700 text-white p-1 rounded-full">
                                                  {e?.reason}
                                                </span>
                                                <span
                                                  className={`font-light text-xs ml-2 ${
                                                    e?.hasBeenPaid
                                                      ? "bg-green-700"
                                                      : "bg-red-700"
                                                  } text-white p-1 rounded-full`}
                                                >
                                                  {e?.hasBeenPaid
                                                    ? "Pagado"
                                                    : "Pendiente"}
                                                </span>
                                              </div>
                                              <div className="flex flex-col-reverse">
                                                <span className="font-semibold text-slate-400 mx-2">
                                                  {typeof e?.date == "string"
                                                    ? e?.date
                                                    : new Date(
                                                        e?.date?.seconds * 1000
                                                      )
                                                        .toLocaleDateString()
                                                        .replaceAll("/", "-")}
                                                </span>
                                              </div>
                                            </span>
                                            <span className="text-xs flex flex-col sm:flex md:flex lg:flex leading-tight text-black/80 ml-2">
                                              <div className="flex">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  strokeWidth={1.5}
                                                  stroke="currentColor"
                                                  className="w-3 h-4"
                                                >
                                                  <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                                  />
                                                </svg>

                                                <div className="text-xs ml-1">
                                                  {`${e.createdBy} - ${e.praxisName}`}
                                                </div>
                                              </div>
                                            </span>
                                          </div>
                                        </div>

                                        <div className="mt-2 text-xs leading-tight text-black/80 w-full">
                                          <div className="font-semibold whitespace-pre-wrap text-slate-700 text-black sm:ml-2 flex flex-wrap text-clip overflow-scroll">
                                            {e?.description}
                                          </div>
                                        </div>

                                        <div className="mt-2 text-xs leading-tight text-black/80 w-full">
                                          <div className="font-semibold whitespace-pre-wrap text-slate-700 text-black sm:ml-2 flex flex-wrap text-clip overflow-scroll">
                                            Precio: {e?.price}
                                          </div>
                                          {totalPayments !== e?.price && (
                                            <div className="font-semibold whitespace-pre-wrap text-slate-700 text-black sm:ml-2 flex flex-wrap text-clip overflow-scroll">
                                              Pagado:
                                              {totalPayments}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                    {showMore === e?.id && (
                                      <>
                                        {e?.payments !== undefined &&
                                        e?.payments?.length > 0 ? (
                                          e.payments.map((payment) => {
                                            return (
                                              <li
                                                className="relative flex justify-between p-6 mb-2 border-0 rounded-t-inherit rounded-xl bg-green-600 bg-slate-850"
                                                key={payment?.id}
                                              >
                                                <div className="flex flex-col w-full">
                                                  <div className="flex flex-col">
                                                    <div>
                                                      <span className="mb-1 text-xs flex items-center justify-between leading-tight text-black/80">
                                                        <div className="m-1">
                                                          <span className="font-light text-xs bg-slate-700 text-white p-1 rounded-full">
                                                            {payment?.invoice}
                                                          </span>
                                                        </div>
                                                        <div className="flex flex-col-reverse">
                                                          <span className="font-semibold text-white mx-2">
                                                            {typeof payment?.date ==
                                                            "string"
                                                              ? payment?.date
                                                              : new Date(
                                                                  payment?.date
                                                                    ?.seconds *
                                                                    1000
                                                                )
                                                                  .toLocaleDateString()
                                                                  .replaceAll(
                                                                    "/",
                                                                    "-"
                                                                  )}
                                                          </span>
                                                        </div>
                                                      </span>
                                                      <span className="text-xs flex flex-col sm:flex md:flex lg:flex leading-tight text-black/80 ml-2">
                                                        <div className="flex">
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={1.5}
                                                            stroke="currentColor"
                                                            className="w-3 h-4 text-white"
                                                          >
                                                            <path
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                                            />
                                                          </svg>

                                                          <div className="text-xs ml-1 text-white">
                                                            {`${payment.amount}`}
                                                          </div>
                                                        </div>
                                                      </span>
                                                    </div>
                                                  </div>

                                                  <div className="mt-2 text-xs leading-tight text-black/80 w-full">
                                                    <div className="font-semibold whitespace-pre-wrap text-white text-black sm:ml-2 flex flex-wrap text-clip overflow-scroll">
                                                      {payment?.description}
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          })
                                        ) : (
                                          <div>No hay Pagos registrados</div>
                                        )}
                                      </>
                                    )}

                                    {/* {e?.payments !== undefined &&
                                    e?.payments?.length > 0 ? (
                                      e?.payments.map((payment) => {
                                        return (
                                          <li
                                            className="relative flex justify-between p-6 mb-2 border-0 rounded-t-inherit rounded-xl bg-green-300 bg-slate-850"
                                            key={payment?.id}
                                          >
                                            <div className="flex flex-col w-full">
                                              <div className="flex flex-col">
                                                <div>
                                                  <span className="mb-1 text-xs flex items-center justify-between leading-tight text-black/80">
                                                    <div className="m-1">
                                                      <span className="font-light text-xs bg-slate-700 text-white p-1 rounded-full">
                                                        {payment?.reason}
                                                      </span>
                                                      <span
                                                        className={`font-light text-xs ml-2 ${
                                                          payment?.hasBeenPaid
                                                            ? "bg-green-700"
                                                            : "bg-red-700"
                                                        } text-white p-1 rounded-full`}
                                                      >
                                                        {payment?.hasBeenPaid
                                                          ? "Pagado"
                                                          : "Pendiente"}
                                                      </span>
                                                    </div>
                                                    <div className="flex flex-col-reverse">
                                                      <span className="font-semibold text-slate-400 mx-2">
                                                        {typeof payment?.date ==
                                                        "string"
                                                          ? payment?.date
                                                          : new Date(
                                                              payment?.date
                                                                ?.seconds * 1000
                                                            )
                                                              .toLocaleDateString()
                                                              .replaceAll(
                                                                "/",
                                                                "-"
                                                              )}
                                                      </span>
                                                    </div>
                                                  </span>
                                                  <span className="text-xs flex flex-col sm:flex md:flex lg:flex leading-tight text-black/80 ml-2">
                                                    <div className="flex">
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-3 h-4"
                                                      >
                                                        <path
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                                        />
                                                      </svg>

                                                      <div className="text-xs ml-1">
                                                        {`${payment.createdBy} - ${payment.praxisName}`}
                                                      </div>
                                                    </div>
                                                  </span>
                                                </div>
                                              </div>

                                              <div className="mt-2 text-xs leading-tight text-black/80 w-full">
                                                <div className="font-semibold whitespace-pre-wrap text-slate-700 text-black sm:ml-2 flex flex-wrap text-clip overflow-scroll">
                                                  {payment?.description}
                                                </div>
                                              </div>

                                              <div className="mt-2 text-xs leading-tight text-black/80 w-full">
                                                <div className="font-semibold whitespace-pre-wrap text-slate-700 text-black sm:ml-2 flex flex-wrap text-clip overflow-scroll">
                                                  {payment?.hasBeenPaid
                                                    ? "Pagado"
                                                    : "Pendiente"}
                                                </div>
                                              </div>

                                              <div className="mt-2 text-xs leading-tight text-black/80 w-full">
                                                <div className="font-semibold whitespace-pre-wrap text-slate-700 text-black sm:ml-2 flex flex-wrap text-clip overflow-scroll">
                                                  Precio: {payment?.price}
                                                </div>
                                                <div className="font-semibold whitespace-pre-wrap text-slate-700 text-black sm:ml-2 flex flex-wrap text-clip overflow-scroll">
                                                  Pagado:{" "}
                                                  {payment?.payments.map(
                                                    (payment) => payment.amount
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        );
                                      })
                                    ) : (
                                      <div>No hay Pagos registrados</div>
                                    )} */}
                                  </>
                                );
                              })
                            ) : (
                              <div className="flex justify-center w-full">
                                <div className="w-3/4 text-center bg-white border border-gray-200 rounded-lg shadow p-3">
                                  <div>
                                    Agrega un historial del Paciente aquí
                                  </div>
                                </div>
                              </div>
                            )
                          ) : (
                            <div
                              className="flex items-center justify-center"
                              style={{ height: "70vh", width: "80vw" }}
                            >
                              <Spinner />
                            </div>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    </Navbar>
  );
};

export default SelfService;
