import React, { useEffect, useState } from "react";
import Spinner from "../../UI/Spinner";
import WizardUI from "../../UI/Wizard/WizardUI";
import { useSelector } from "react-redux";
import PatientQuestionaire from "./PatientQuestionaire";
import odontogramModel from "./odontogramModel";
import PatientDetails from "./PatientDetails";
import PatientChecks from "./PatientChecks";
import toast from "react-hot-toast";
import { clientPoster } from "../../../firebase/firebase.patient";
import { useNavigate } from "react-router";
import AI from "../../AI/AI";

const AddPatientModal = ({ modalState, modalSetter }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [editModeState, setEditModeState] = useState(true);
  const [canSave, setCanSave] = useState(false);
  const [canContinue, setCanContinue] = useState(false);

  const [id, setId] = useState(null);
  const [patientDetails, setPatientDetails] = useState(null);
  const [questionaire, setQuestionaire] = useState(null);
  const [odontologicPast, setOdontologicPast] = useState(null);
  const [praxtisId, setPraxisId] = useState(null);
  const localPraxis = useSelector((state) => state.user?.userDetails);
  const [hasSubmited, setHasSubmited] = useState(false);
  // Details generated by Voice Assistant
  const [AIDetails, setAIDetails] = useState(undefined);

  useEffect(() => {
    if (localPraxis && localPraxis.praxisId) {
      setPraxisId(localPraxis?.praxisId);
    }
  }, [localPraxis]);

  let client = {
    id: id,
    praxisId: [praxtisId],
    odontoId: patientDetails?.odontoId,
    personalDetails: {
      name: patientDetails?.name,
      tutorName: patientDetails?.tutorName,
      tel: patientDetails?.tel,
      nationality: patientDetails?.nationality,
      birthdate: patientDetails?.birthdate,
      maritalStatus: patientDetails?.maritalStatus,
      gender: patientDetails?.gender,
      profession: patientDetails?.profession,
      personalAddress: patientDetails?.personalAddress,
      email: patientDetails?.email,
      identityNumber: patientDetails?.identityNumber,
    },
    medicHistory: {
      isCurrentlyUnderMedicalTreatment:
        questionaire?.isCurrentlyUnderMedicalTreatment,
      isUsingMedicine: questionaire?.isUsingMedicine,
      needsBloodTransf: questionaire?.needsBloodTransf,
      hadSurgery: questionaire?.hadSurgery,
      alergyToMedicine: questionaire?.alergyToMedicine,
      smokes: questionaire?.smokes,
      drinks: questionaire?.drinks,
      pregnant: questionaire?.pregnant,
      canUseAnestesia: questionaire?.canUseAnestesia,
    },
    odontologicPast: {
      lastMedicalCheck: odontologicPast?.lastMedicalCheck,
      reasonForLosingTheet: odontologicPast?.reasonForLosingTheet,
      reasonForLosingTheetText: odontologicPast?.reasonForLosingTheetText,
      mouthHygiene: odontologicPast?.mouthHygiene,
      lastVisit: `${new Date(odontologicPast?.lastVisit)}`,
    },
    odontograma: odontogramModel,
  };

  const saveClientHandler = () => {
    if (praxtisId == null) {
      toast.error(`Porfavor vuelva a iniciar sesion`, "Sign Out Error");
      return;
    }
    let newId = Math.random().toString(16).slice(2);
    setId(newId);
    client.id = newId;
    clientPoster("patients", newId, client, setHasSubmited);
  };

  useEffect(() => {
    if (hasSubmited) {
      navigate(`/${id}`);
    }
  }, [hasSubmited]);
  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
        style={{ zIndex: "10000" }}
      >
        <div className="relative my-6 mx-auto lg:w-80vw md:w-70vw sm:w-80vw w-full">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none md:px-12">
            {/*header*/}
            {
              <>
                <div className="flex items-center w-full justify-between p-2 m-2 border-b border-solid border-blueGray-200 rounded-t">
                  <div className="flex items-left">
                    <h3 className="text-xl font-semibold">Nuevo Paciente</h3>
                    <AI setAIDetails={setAIDetails} type="newPatient" />
                  </div>
                  <button
                    className="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => modalSetter(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18 18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="px-5 w-full">
                  <WizardUI
                    canSave={canSave}
                    saveSetter={setCanSave}
                    canContinue={canContinue}
                  >
                    <div>
                      <PatientDetails
                        patientDetails={patientDetails}
                        detailsSetter={setPatientDetails}
                        setCanContinue={setCanContinue}
                        AIDetails={AIDetails}
                      />
                    </div>

                    <div>
                      <PatientQuestionaire
                        questionaire={questionaire}
                        questionaireSetter={setQuestionaire}
                        setCanContinue={setCanContinue}
                      />
                    </div>

                    <div>
                      <PatientChecks
                        odontologicPast={odontologicPast}
                        pastSetter={setOdontologicPast}
                        setCanContinue={setCanContinue}
                      />
                    </div>
                  </WizardUI>
                </div>
                <div className="flex items-center justify-end p-3 border-t border-solid border-blueGray-200 rounded-b">
                  {canSave && (
                    <div className="flex bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                      <button
                        className=""
                        type="button"
                        onClick={saveClientHandler}
                      >
                        Guardar
                      </button>
                      {isLoading && (
                        <div className="ml-2">
                          <Spinner size="small" fill="fill-emerald-800" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            }
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddPatientModal;
