import "./AIButton.css";

const ButtonContent = ({ isListening, isAIThinking, isAIReplying }) => {
  if (isAIReplying) {
    return (
      <span className="replying-container">
        <span className="replying-icon"></span>
      </span>
    );
  }

  if (isAIThinking) {
    return (
      <span className="thinking-container">
        <span className="thinking-icon"></span>
      </span>
    );
  }

  if (isListening) {
    return (
      <span className="dots-container">
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
      </span>
    );
  }

  return <span className="default-icon">StarkyAI</span>;
};

export const AIButton = ({
  handleStartListening,
  disabled,
  isListening,
  isAIThinking,
  isAIReplying,
}) => (
  <button
    onClick={handleStartListening}
    disabled={disabled}
    className={`chat-button ${
      isListening
        ? "listening"
        : isAIThinking
        ? "thinking"
        : isAIReplying
        ? "replying"
        : ""
    }`}
  >
    <ButtonContent
      isListening={isListening}
      isAIThinking={isAIThinking}
      isAIReplying={isAIReplying}
    />
  </button>
);
