import React, { useState } from "react";
import Dropdown from "../../UI/Dropdown";
import {
  clientPoster,
  clientUpdater,
} from "../../../firebase/firebase.patient";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { calculateAge } from "../../../utils/calculateAge";
import odontogramModel from "./odontogramModel";
import { DatePickerIcon } from "../../UI/DatePickerIcon";
import NationalityDropdown from "../../UI/NationalityDropdown";

const AddPatient = ({
  editMode,
  headerTitle,
  data,
  setData,
  showMore,
  view,
}) => {
  const navigate = useNavigate();
  const [editModeState, setEditModeState] = useState(true);

  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [tutorName, setTutorName] = useState("");
  const [tel, setTel] = useState("");
  const [nationality, setNationality] = useState("Paraguay");
  const [birthdate, setBirthdate] = useState("");
  const [age, setAge] = useState(null);
  const [maritalStatus, setMaritalStatus] = useState("");
  const [gender, setGender] = useState("");
  const [profession, setProfession] = useState("");
  const [taxName, setTaxName] = useState("");
  const [ruc, setRUC] = useState("");
  const [personalAddress, setPersonalAddress] = useState("");
  const [email, setEmail] = useState("");
  const [ci, setCI] = useState("");
  const [odontoId, setOdontoId] = useState("");
  // Medical info
  const [
    isCurrentlyUnderMedicalTreatment,
    setIsCurrentlyUnderMedicalTreatment,
  ] = useState({ isMedicalTreatment: false, treatments: "" });
  const [isUsingMedicine, setUsingMedicine] = useState({
    isUsingMedicine: false,
    medicines: "",
  });
  const [needsBloodTransf, setNeedsBloodTransf] = useState({
    needsBloodTransf: false,
    reasons: "",
  });
  const [hadSurgery, setHadSurgery] = useState({
    hadSurgery: false,
    which: "",
  });
  const [alergyToMedicine, setAlergyToMedicine] = useState({
    alergyToMedicine: false,
    which: "",
  });
  const [smokes, setSmokes] = useState({
    smokes: false,
    howLong: "",
    howMany: "",
  });
  const [drinks, setDrinks] = useState({
    drinks: false,
    howLong: "",
    howMany: "",
  });
  const [pregnant, setPregnant] = useState({ pregnant: false, weeks: 0 });
  const [canUseAnestesia, setCanUseAnestesia] = useState(false);
  const [praxtisId, setPraxisId] = useState(null);
  const localPraxis = useSelector((state) => state.user?.userDetails);
  const [sicknessList, setSicknessList] = useState([
    { name: "Tuberculosis", has: false },
    { name: "Lepra", has: false },
    { name: "STDs", has: false },
    { name: "Hepatitis", has: false },
    { name: "AIDS", has: false },
    { name: "Asma", has: false },
    { name: "Alergias", has: false },
    { name: "Sinusitis", has: false },
    { name: "Lesión del Hígado", has: false },
    { name: "Ulcera", has: false },
    { name: "Enf. Cardíaca", has: false },
    { name: "Epilepsia", has: false },
    { name: "HipertArterial", has: false },
    { name: "Anemia", has: false },
    { name: "Hemofilia", has: false },
    { name: "Convulsiones", has: false },
    { name: "Desmayos", has: false },
    { name: "Problemas de Coagulacion", has: false },
    { name: "Diabetes", has: false },
    { name: "Otros", has: false, other: "" },
  ]);
  // Medical Records
  const [lastMedicalCheck, setLastMedicalCheck] = useState("");
  const [reasonForLosingTheet, setReasonForLosingTheet] = useState("");
  const [reasonForLosingTheetText, setReasonForLosingTheetText] = useState("");
  const [mouthHygiene, setMouthHygiene] = useState("");
  const [lastVisit, setLastVisit] = useState(new Date());
  //Has Submited
  const [hasSubmited, setHasSubmited] = useState(false);

  useEffect(() => {
    if (localPraxis && localPraxis.praxisId) {
      setPraxisId(localPraxis?.praxisId);
    }
  }, [localPraxis]);

  useEffect(() => {
    if (data) {
      setId(data.id);
      setPraxisId(data.praxisId);
      setName(data.personalDetails.name);
      setEmail(
        data.personalDetails?.email ? data.personalDetails?.email : null
      );
      setTutorName(data.personalDetails.tutorName);
      setTel(data.personalDetails.tel);
      setNationality(data.personalDetails.nationality);
      console.log(data.personalDetails.nationality);
      data.personalDetails.identityNumber &&
        setCI(data.personalDetails.identityNumber);
      data?.odontoId && setOdontoId(data?.odontoId);
      setBirthdate(data.personalDetails.birthdate);
      setMaritalStatus(data.personalDetails.maritalStatus);
      setGender(data.personalDetails.gender);
      setProfession(data.personalDetails.profession);
      setPersonalAddress(data.personalDetails.personalAddress);
      setTaxName(
        data.personalDetails.taxName ? data.personalDetails.taxName : ""
      );
      setRUC(data.personalDetails.ruc ? data.personalDetails.ruc : "");

      setIsCurrentlyUnderMedicalTreatment(
        data.medicHistory.isCurrentlyUnderMedicalTreatment
      );
      setUsingMedicine(data.medicHistory.isUsingMedicine);
      setNeedsBloodTransf(data.medicHistory.needsBloodTransf);
      setHadSurgery(data.medicHistory.hadSurgery);
      setAlergyToMedicine(data.medicHistory.alergyToMedicine);
      setSmokes(data.medicHistory.smokes);
      setDrinks(data.medicHistory.drinks);
      setPregnant(data.medicHistory.pregnant);
      setCanUseAnestesia(data.medicHistory.canUseAnestesia);

      setLastMedicalCheck(data.odontologicPast.lastMedicalCheck);
      setReasonForLosingTheet(data.odontologicPast.reasonForLosingTheet);
      if (data.odontologicPast.reasonForLosingTheet == "Otros") {
        setReasonForLosingTheet(data.odontologicPast.reasonForLosingTheetText);
      }
      setMouthHygiene(data.odontologicPast.mouthHygiene);
      setLastVisit(new Date(data.odontologicPast.lastVisit));
      data.medicHistory.sicknessList &&
        setSicknessList(data.medicHistory.sicknessList);
    }
  }, [data]);

  useEffect(() => {
    if (birthdate) {
      var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
      var convertedBd = new Date(birthdate.replace(pattern, "$3-$2-$1"));
      let currentAge = calculateAge(convertedBd);
      setAge(currentAge);
    }
  }, [birthdate]);

  let formStyle = `text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg ${
    editModeState ? "bg-gray-200" : ""
  } ring-offset-current ring-offset-2 ring-gray-400`;

  let client = {
    id: id,
    praxisId: praxtisId,
    odontoId: nationality?.value ? `${ci}-${nationality?.value}` : odontoId,
    personalDetails: {
      name: name,
      tutorName: tutorName,
      tel: tel,
      nationality: nationality?.label
        ? nationality?.label?.split(" ")[1]
        : nationality,
      birthdate: birthdate,
      maritalStatus: maritalStatus,
      gender: gender,
      profession: profession,
      personalAddress: personalAddress,
      email: email,
      identityNumber: ci,
      taxName: taxName,
      ruc: ruc,
    },
    medicHistory: {
      isCurrentlyUnderMedicalTreatment: isCurrentlyUnderMedicalTreatment,
      isUsingMedicine: isUsingMedicine,
      needsBloodTransf: needsBloodTransf,
      hadSurgery: hadSurgery,
      alergyToMedicine: alergyToMedicine,
      smokes: smokes,
      drinks: drinks,
      pregnant: pregnant,
      canUseAnestesia: canUseAnestesia,
      sicknessList: sicknessList,
    },
    odontologicPast: {
      lastMedicalCheck: lastMedicalCheck,
      reasonForLosingTheet: reasonForLosingTheet,
      reasonForLosingTheetText: reasonForLosingTheetText,
      mouthHygiene: mouthHygiene,
      lastVisit: `${new Date(lastVisit)}`,
    },
    odontograma: odontogramModel,
  };

  const saveClientHandler = async () => {
    if (praxtisId == null) {
      toast.error(`Porfavor vuelva a iniciar sesion`, "Sign Out Error");
      return;
    }
    if (editMode === undefined && id === null) {
      let newId = Math.random().toString(16).slice(2);
      setId(newId);
      client.id = newId;
      clientPoster("patients", newId, client, setHasSubmited);
    } else {
      await clientUpdater("patients", id, client, setHasSubmited);
      setEditModeState(false);
      setData(client);
    }
  };

  useEffect(() => {
    if (hasSubmited) {
      navigate(`/${id}`);
    }
  }, [hasSubmited]);

  useEffect(() => {
    if (editMode !== undefined) {
      setEditModeState(editMode);
    }
  }, [editMode]);

  return (
    <>
      <div className="">
        <div
          className={`flex overflow-scroll
          md:h-[${!showMore ? "64vh" : "74vh"}] 
          h-[${!showMore ? "65vh" : "50vh"}]`}
        >
          <div className="w-full p-2">
            {view !== "patientView" && (
              <div className="flex flex-row justify-end mx-3">
                {editMode !== undefined && (
                  <div className="flex flex-row-reverse p-1">
                    <div className="flex-initial pl-1">
                      <button
                        onClick={() => setEditModeState(!editModeState)}
                        type="button"
                        className="flex items-center px-0.5 py-1 font-medium tracking-wide text-white capitalize   bg-black rounded-md hover:bg-gray-800  focus:outline-none focus:bg-gray-900  transition duration-300 transform active:scale-95 ease-in-out"
                      >
                        {!editModeState ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-pencil w-4 h-5 m-1"
                            viewBox="0 0 16 16"
                            id="IconChangeColor"
                          >
                            {" "}
                            <path
                              d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                              id="mainIconPathAttribute"
                              fill="#ffffff"
                            ></path>{" "}
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-eye w-4 h-5 m-1"
                            viewBox="0 0 16 16"
                            id="IconChangeColor"
                          >
                            {" "}
                            <path
                              d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                              id="mainIconPathAttribute"
                            ></path>{" "}
                            <path
                              d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                              id="mainIconPathAttribute"
                            ></path>{" "}
                          </svg>
                        )}
                      </button>
                    </div>
                  </div>
                )}
                {editModeState && (
                  <div className="flex flex-row-reverse p-1">
                    <div className="flex-initial pl-1">
                      <button
                        onClick={saveClientHandler}
                        type="button"
                        className="flex items-center px-0.5 py-1 font-medium tracking-wide text-white capitalize bg-black rounded-md hover:bg-gray-800 focus:outline-none focus:bg-gray-900 transition duration-300 transform active:scale-95 ease-in-out"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#FFFFFF"
                          className="w-4 h-5 m-1"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none"></path>
                          <path
                            d="M5 5v14h14V7.83L16.17 5H5zm7 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-8H6V6h9v4z"
                            opacity=".3"
                          ></path>
                          <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z"></path>
                        </svg>
                        {/* <span className="mx-1 text-sm">Guardar</span> */}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="flex flex-col">
              <div className="flex">
                <div className="personalDetails w-3/6">
                  <div className="flex">
                    <div className="flex py-5 w-full pl-5 overflow-hidden">
                      <svg
                        className="inline align-text-top"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000000"
                      >
                        <g>
                          <path
                            d="m4.88889,2.07407l14.22222,0l0,20l-14.22222,0l0,-20z"
                            fill="none"
                            id="svg_1"
                            stroke="null"
                          ></path>
                          <path
                            d="m7.07935,0.05664c-3.87,0 -7,3.13 -7,7c0,5.25 7,13 7,13s7,-7.75 7,-13c0,-3.87 -3.13,-7 -7,-7zm-5,7c0,-2.76 2.24,-5 5,-5s5,2.24 5,5c0,2.88 -2.88,7.19 -5,9.88c-2.08,-2.67 -5,-7.03 -5,-9.88z"
                            id="svg_2"
                          ></path>
                          <circle
                            cx="7.04807"
                            cy="6.97256"
                            r="2.5"
                            id="svg_3"
                          ></circle>
                        </g>
                      </svg>
                      <h6 className="mb-0 text-black">Datos Personales</h6>
                    </div>
                  </div>

                  <div className="px-5 pb-5">
                    <div className="flex flex-col lg:flex-row py-3">
                      <div className="w-full lg:w-2/4 lg:mx-1">
                        <label>Nombre del Paciente</label>
                        <input
                          onChange={(e) => setName(e.target.value)}
                          defaultValue={name}
                          className={formStyle}
                          disabled={!editModeState}
                        />
                      </div>

                      <div className="w-full lg:w-2/4 lg:mx-1">
                        <label>Nombre del tutor (a):</label>
                        <input
                          onChange={(e) => setTutorName(e.target.value)}
                          defaultValue={tutorName}
                          className={formStyle}
                          disabled={!editModeState}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col lg:flex-row py-3">
                      <div className="w-full lg:w-2/4 lg:mx-1">
                        <label>Tel particular</label>
                        <input
                          onChange={(e) => setTel(e.target.value)}
                          defaultValue={tel}
                          className={formStyle}
                          disabled={!editModeState}
                        />
                      </div>

                      <div className="w-full lg:w-2/4 lg:mx-1">
                        <label>Email</label>
                        <input
                          onChange={(e) => setEmail(e.target.value)}
                          defaultValue={email}
                          className={formStyle}
                          disabled={!editModeState}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col lg:flex-row py-3">
                      <div className="w-full lg:w-2/4 lg:mx-1">
                        <label>CI</label>
                        <input
                          onChange={(e) => setCI(e.target.value)}
                          defaultValue={ci}
                          className={formStyle}
                          disabled={!editModeState}
                        />
                      </div>

                      <div className="w-full lg:w-2/4 lg:mx-1">
                        <label>Nacionalidad</label>
                        <NationalityDropdown
                          currentValueSetter={(e) =>
                            setNationality(JSON.parse(e))
                          }
                          className={formStyle}
                          defaultValue={data.personalDetails.nationality}
                          disabled={!editModeState}
                        />
                      </div>
                    </div>
                    {/* // */}
                    <div className="flex flex-col lg:flex-row py-3">
                      <div className="w-full lg:w-2/4 lg:mx-1">
                        <label>Nacimiento</label>
                        <input
                          onChange={(e) => setBirthdate(e.target.value)}
                          defaultValue={birthdate}
                          className={formStyle}
                          disabled={!editModeState}
                        />
                      </div>
                      <div className="w-full lg:w-2/4 lg:mx-1">
                        <label>Estado civil</label>
                        <input
                          onChange={(e) => setMaritalStatus(e.target.value)}
                          defaultValue={maritalStatus}
                          className={formStyle}
                          disabled={!editModeState}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col lg:flex-row py-3">
                      <div className="w-full lg:w-2/4 lg:mx-1">
                        <label>Sexo</label>
                        <Dropdown
                          data={[
                            { title: "Femenino ", id: 1 },
                            { title: "Masculino", id: 2 },
                            { title: "Otro", id: 3 },
                            { title: "Prefiere no decir", id: 4 },
                          ]}
                          currentValueSetter={setGender}
                          disabled={!editModeState}
                          defaultValue={gender}
                          expectedValue={"title"}
                        />
                      </div>

                      <div className="w-full lg:w-2/4 lg:mx-1">
                        <label>Profesión</label>
                        <input
                          onChange={(e) => setProfession(e.target.value)}
                          defaultValue={profession}
                          className={formStyle}
                          disabled={!editModeState}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col lg:flex-row py-3">
                      <div className="w-full lg:w-2/4 lg:mx-1">
                        <label>Razon Social</label>
                        <input
                          onChange={(e) => setTaxName(e.target.value)}
                          defaultValue={taxName}
                          className={formStyle}
                          disabled={!editModeState}
                        />
                      </div>

                      <div className="w-full lg:w-2/4 lg:mx-1">
                        <label>RUC</label>
                        <input
                          onChange={(e) => setRUC(e.target.value)}
                          defaultValue={ruc}
                          className={formStyle}
                          disabled={!editModeState}
                        />
                      </div>
                    </div>

                    <div>
                      <label>Dirección personal</label>
                      <input
                        onChange={(e) => setPersonalAddress(e.target.value)}
                        defaultValue={personalAddress}
                        className={formStyle}
                        disabled={!editModeState}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="medicalDetails">
                    <div className="flex">
                      <div className="flex py-5 pl-5 overflow-hidden">
                        <svg
                          className="inline align-text-top"
                          width="21"
                          height="20.5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#000000"
                        >
                          <g>
                            <path
                              d="m4.88889,2.07407l14.22222,0l0,20l-14.22222,0l0,-20z"
                              fill="none"
                              id="svg_1"
                              stroke="null"
                            ></path>
                            <path
                              d="m7.07935,0.05664c-3.87,0 -7,3.13 -7,7c0,5.25 7,13 7,13s7,-7.75 7,-13c0,-3.87 -3.13,-7 -7,-7zm-5,7c0,-2.76 2.24,-5 5,-5s5,2.24 5,5c0,2.88 -2.88,7.19 -5,9.88c-2.08,-2.67 -5,-7.03 -5,-9.88z"
                              id="svg_2"
                            ></path>
                            <circle
                              cx="7.04807"
                              cy="6.97256"
                              r="2.5"
                              id="svg_3"
                            ></circle>
                          </g>
                        </svg>
                        <h6 className="mb-0 text-black">Detalles Medicos</h6>
                      </div>
                      <div className="flex-none pt-2.5 pr-2.5 pl-1"></div>
                    </div>

                    <div className="px-5 pb-5">
                      <div className="flex items-center pt-3">
                        <div className="flex flex-col">
                          <div className="flex">
                            <input
                              type="checkbox"
                              id="medicalTreatment"
                              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                              checked={
                                isCurrentlyUnderMedicalTreatment.isMedicalTreatment
                              }
                              onChange={(e) =>
                                setIsCurrentlyUnderMedicalTreatment({
                                  isMedicalTreatment: e.target.checked,
                                  treatments: [],
                                })
                              }
                              disabled={!editModeState}
                            />
                            <label
                              htmlFor="medicalTreatment"
                              className="block ml-2 text-sm text-gray-900"
                            >
                              ¿Está siendo sometido a algún tratamiento médico
                              actualmente?
                            </label>
                          </div>
                          <div className="">
                            {isCurrentlyUnderMedicalTreatment.isMedicalTreatment &&
                              isCurrentlyUnderMedicalTreatment
                                .isMedicalTreatment.length > 0 &&
                              isCurrentlyUnderMedicalTreatment.medicines.map(
                                (e) => (
                                  <input
                                    checked={e}
                                    key={e}
                                    disabled={!editModeState}
                                  />
                                )
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center pt-3">
                        <div className="flex flex-col">
                          <div className="flex">
                            <input
                              type="checkbox"
                              id="useOfMedicine"
                              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                              checked={isUsingMedicine.isUsingMedicine}
                              onChange={(e) =>
                                setUsingMedicine({
                                  isUsingMedicine: e.target.checked,
                                  medicines: [],
                                })
                              }
                              disabled={!editModeState}
                            />
                            <label
                              htmlFor="useOfMedicine"
                              className="block ml-2 text-sm text-gray-900"
                            >
                              ¿Está haciendo uso de algún medicamento?
                            </label>
                          </div>
                          <div className="flex flex-col">
                            {isUsingMedicine.isUsingMedicine && (
                              <>
                                <input
                                  placeholder="¿Cuáles?"
                                  onChange={(e) => {
                                    setUsingMedicine({
                                      isUsingMedicine:
                                        isUsingMedicine.isUsingMedicine,
                                      medicines: e.target.value,
                                    });
                                  }}
                                  className="mt-3 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  disabled={!editModeState}
                                  defaultValue={isUsingMedicine.medicines}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center pt-3">
                        <div className="flex flex-col">
                          <div className="flex">
                            <input
                              type="checkbox"
                              id="bloodTreatment"
                              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                              checked={needsBloodTransf.needsBloodTransf}
                              onChange={(e) =>
                                setNeedsBloodTransf({
                                  needsBloodTransf: e.target.checked,
                                  reasons: [],
                                })
                              }
                              disabled={!editModeState}
                            />
                            <label
                              htmlFor="bloodTreatment"
                              className="block ml-2 text-sm text-gray-900"
                            >
                              ¿Necesita o necesitó periódicamente de transfusión
                              sanguínea o derivados?
                            </label>
                          </div>
                          <div className="">
                            {needsBloodTransf.needsBloodTransf && (
                              <>
                                <input
                                  placeholder="¿Motivo?"
                                  onChange={(e) =>
                                    setNeedsBloodTransf({
                                      needsBloodTransf:
                                        needsBloodTransf.needsBloodTransf,
                                      reasons: e.target.value,
                                    })
                                  }
                                  className="mt-3 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  disabled={!editModeState}
                                  defaultValue={needsBloodTransf.reasons}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center pt-3">
                        <div className="flex flex-col">
                          <div className="flex">
                            <input
                              type="checkbox"
                              id="surgery"
                              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                              checked={hadSurgery.hadSurgery}
                              onChange={(e) =>
                                setHadSurgery({
                                  hadSurgery: e.target.checked,
                                  which: "",
                                })
                              }
                              disabled={!editModeState}
                            />
                            <label
                              htmlFor="surgery"
                              className="block ml-2 text-sm text-gray-900"
                            >
                              ¿Fue sometido a alguna cirugía?
                            </label>
                          </div>
                          <div className="">
                            {hadSurgery.hadSurgery && (
                              <>
                                <input
                                  placeholder="¿Cuál?"
                                  onChange={(e) =>
                                    setHadSurgery({
                                      hadSurgery: hadSurgery.hadSurgery,
                                      which: e.target.value,
                                    })
                                  }
                                  className="mt-3 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  disabled={!editModeState}
                                  defaultValue={hadSurgery.which}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center pt-3">
                        <div className="flex flex-col">
                          <div className="flex">
                            <input
                              type="checkbox"
                              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                              checked={alergyToMedicine.alergyToMedicine}
                              onChange={(e) =>
                                setAlergyToMedicine({
                                  alergyToMedicine: e.target.checked,
                                  which: "",
                                })
                              }
                              disabled={!editModeState}
                            />
                            <label
                              htmlFor="safeAdress"
                              className="block ml-2 text-sm text-gray-900"
                            >
                              ¿Presenta alergia a algún alimento o medicamento?
                            </label>
                          </div>
                          <div className="">
                            {alergyToMedicine.alergyToMedicine && (
                              <>
                                <input
                                  placeholder="¿Cuál?"
                                  onChange={(e) =>
                                    setAlergyToMedicine({
                                      alergyToMedicine:
                                        alergyToMedicine.alergyToMedicine,
                                      which: e.target.value,
                                    })
                                  }
                                  className="mt-3 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  disabled={!editModeState}
                                  defaultValue={alergyToMedicine.which}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center pt-3">
                        <div className="flex flex-col">
                          <div className="flex">
                            <input
                              id="smoke"
                              type="checkbox"
                              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                              checked={smokes.smokes}
                              onChange={(e) =>
                                setSmokes({
                                  smokes: e.target.checked,
                                  howLong: 0,
                                  howMany: 0,
                                })
                              }
                              disabled={!editModeState}
                            />
                            <label
                              htmlFor="smoke"
                              className="block ml-2 text-sm text-gray-900"
                            >
                              ¿Fuma?
                            </label>
                          </div>
                          <div className="">
                            {smokes.smokes && (
                              <>
                                <div className="flex flex-col items-baseline m-2">
                                  <label
                                    htmlFor="howLong"
                                    className="block ml-2 text-sm text-gray-900 mr-3"
                                  >
                                    ¿Hace cuanto tiempo? (En Meses)
                                  </label>
                                  <input
                                    id="howLong"
                                    className="w-24 h-8 shadow appearance-none border rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    defaultValue={smokes.howLong}
                                    onChange={(e) =>
                                      setSmokes({
                                        smokes: smokes.smokes,
                                        howLong: e.target.value,
                                        howMany: smokes.howMany,
                                      })
                                    }
                                    disabled={!editModeState}
                                  />
                                </div>
                                <div className="flex flex-col items-baseline m-2">
                                  <label
                                    htmlFor="howMany"
                                    className="block ml-2 text-sm text-gray-900 mr-3"
                                  >
                                    Cantidad por día
                                  </label>
                                  <input
                                    id="howMany"
                                    className="w-24 h-8 shadow appearance-none border rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    defaultValue={smokes.howMany}
                                    onChange={(e) =>
                                      setSmokes({
                                        smokes: smokes.smokes,
                                        howLong: smokes.howLong,
                                        howMany: e.target.value,
                                      })
                                    }
                                    disabled={!editModeState}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center pt-3">
                        <div className="flex flex-col">
                          <div className="flex">
                            <input
                              type="checkbox"
                              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                              checked={drinks.drinks}
                              onChange={(e) =>
                                setDrinks({
                                  drinks: e.target.checked,
                                  howLong: 0,
                                  howMany: 0,
                                })
                              }
                              disabled={!editModeState}
                            />
                            <label
                              htmlFor="safeAdress"
                              className="block ml-2 text-sm text-gray-900"
                            >
                              ¿Toma bebidas alcohólicas?
                            </label>
                          </div>
                          <div className="">
                            {drinks.drinks && (
                              <>
                                <div className="flex m-2 flex-col items-baseline ">
                                  <label
                                    htmlFor="howLong"
                                    className="block ml-2 text-sm text-gray-900 mr-3"
                                  >
                                    ¿Hace cuánto tiempo? (En Meses)
                                  </label>
                                  <input
                                    id="howLong"
                                    className="w-24 h-8 shadow appearance-none border rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    defaultValue={drinks.howLong}
                                    onChange={(e) =>
                                      setDrinks({
                                        drinks: drinks.drinks,
                                        howLong: e.target.value,
                                        howMany: 0,
                                      })
                                    }
                                    disabled={!editModeState}
                                  />
                                </div>
                                <div className="flex flex-col items-baseline m-2">
                                  <label
                                    htmlFor="howMany"
                                    className="block ml-2 text-sm text-gray-900 mr-3"
                                  >
                                    Cantidad por día
                                  </label>
                                  <input
                                    id="howMany"
                                    className="w-24 h-8 shadow appearance-none border rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    defaultValue={drinks.howMany}
                                    onChange={(e) =>
                                      setDrinks({
                                        drinks: drinks.drinks,
                                        howLong: drinks.howLong,
                                        howMany: e.target.value,
                                      })
                                    }
                                    disabled={!editModeState}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center pt-3">
                        <div className="flex flex-col">
                          <div className="flex">
                            <input
                              type="checkbox"
                              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                              checked={pregnant.pregnant}
                              onChange={(e) =>
                                setPregnant({
                                  pregnant: e.target.checked,
                                  weeks: 0,
                                })
                              }
                              disabled={!editModeState}
                            />
                            <label
                              htmlFor="safeAdress"
                              className="block ml-2 text-sm text-gray-900"
                            >
                              ¿Declara estar embarazada?
                            </label>
                          </div>
                          <div className="">
                            {pregnant.pregnant && (
                              <div className="flex flex-col items-baseline m-2">
                                <label
                                  htmlFor="howLong"
                                  className="block ml-2 text-sm text-gray-900 mr-3"
                                >
                                  ¿Cuántas semanas?
                                </label>
                                <input
                                  defaultValue={pregnant.weeks}
                                  id="howLong"
                                  className="w-24 h-8 shadow appearance-none border rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  onChange={(e) =>
                                    setPregnant({
                                      pregnant: pregnant.pregnant,
                                      weeks: JSON.parse(e.target.defaultValue),
                                    })
                                  }
                                  disabled={!editModeState}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center pt-3">
                        <div className="flex flex-col">
                          <div className="flex">
                            <input
                              type="checkbox"
                              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                              checked={canUseAnestesia}
                              onChange={(e) =>
                                setCanUseAnestesia(e.target.checked)
                              }
                              disabled={!editModeState}
                            />
                            <label
                              htmlFor="safeAdress"
                              className="block ml-2 text-sm text-gray-900"
                            >
                              ¿Tolera la anestesia de uso odontológico?
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="medicalDetails">
                    <div className="flex">
                      <div className="flex py-5 pl-5 overflow-hidden">
                        <svg
                          className="inline align-text-top"
                          width="21"
                          height="20.5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#000000"
                        >
                          <g>
                            <path
                              d="m4.88889,2.07407l14.22222,0l0,20l-14.22222,0l0,-20z"
                              fill="none"
                              id="svg_1"
                              stroke="null"
                            ></path>
                            <path
                              d="m7.07935,0.05664c-3.87,0 -7,3.13 -7,7c0,5.25 7,13 7,13s7,-7.75 7,-13c0,-3.87 -3.13,-7 -7,-7zm-5,7c0,-2.76 2.24,-5 5,-5s5,2.24 5,5c0,2.88 -2.88,7.19 -5,9.88c-2.08,-2.67 -5,-7.03 -5,-9.88z"
                              id="svg_2"
                            ></path>
                            <circle
                              cx="7.04807"
                              cy="6.97256"
                              r="2.5"
                              id="svg_3"
                            ></circle>
                          </g>
                        </svg>
                        <h6 className="mb-0 text-black">
                          Antecedentes Odontologicos
                        </h6>
                      </div>
                      <div className="flex-none pt-2.5 pr-2.5 pl-1"></div>
                    </div>

                    <div className="px-5 pb-5">
                      <Dropdown
                        data={[
                          { title: "Menos", id: 1 },
                          { title: "6 Meses", id: 2 },
                          { title: "1 Año", id: 3 },
                          { title: "Más", id: 4 },
                        ]}
                        title="Fecha de su ultima consulta odontológica"
                        currentValueSetter={setLastMedicalCheck}
                        disabled={!editModeState}
                        defaultValue={lastMedicalCheck}
                      />
                      <Dropdown
                        data={[
                          { title: "Caries ", id: 1 },
                          { title: "Accidente", id: 2 },
                          { title: "Movilidad", id: 3 },
                          { title: "Otros", id: 4 },
                          { title: "No hubo perdida de dientes", id: 5 },
                        ]}
                        title="La perdida de sus dientes fue por"
                        currentValueSetter={setReasonForLosingTheet}
                        disabled={!editModeState}
                        defaultValue={reasonForLosingTheet}
                      />
                      {reasonForLosingTheet == "Otros" && (
                        <div>
                          <label>Razon</label>
                          <input
                            onChange={(e) =>
                              setReasonForLosingTheetText(e.target.value)
                            }
                            defaultValue={reasonForLosingTheetText}
                            className={formStyle}
                            disabled={!editModeState}
                          />
                        </div>
                      )}
                      <Dropdown
                        data={[
                          { title: "Adecuada", id: 1 },
                          { title: "Deficiente", id: 2 },
                        ]}
                        title="Higiene bucal"
                        currentValueSetter={setMouthHygiene}
                        disabled={!editModeState}
                        defaultValue={mouthHygiene}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="medicalHistory">
                <div className="flex flex-col">
                  <div className="flex pl-5 overflow-hidden">
                    <svg
                      className="inline align-text-top"
                      width="21"
                      height="20.5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000000"
                    >
                      <g>
                        <path
                          d="m4.88889,2.07407l14.22222,0l0,20l-14.22222,0l0,-20z"
                          fill="none"
                          id="svg_1"
                          stroke="null"
                        ></path>
                        <path
                          d="m7.07935,0.05664c-3.87,0 -7,3.13 -7,7c0,5.25 7,13 7,13s7,-7.75 7,-13c0,-3.87 -3.13,-7 -7,-7zm-5,7c0,-2.76 2.24,-5 5,-5s5,2.24 5,5c0,2.88 -2.88,7.19 -5,9.88c-2.08,-2.67 -5,-7.03 -5,-9.88z"
                          id="svg_2"
                        ></path>
                        <circle
                          cx="7.04807"
                          cy="6.97256"
                          r="2.5"
                          id="svg_3"
                        ></circle>
                      </g>
                    </svg>
                    <h6 className="mb-0 text-black">Historial Clinico</h6>
                  </div>
                  <div className="flex-none m-5 lg:columns-4 md:columns-4 columns-2">
                    <div>
                      {sicknessList &&
                        sicknessList.map((e, i) => {
                          return (
                            <div className="" key={i}>
                              <input
                                type="checkbox"
                                checked={e.has}
                                onChange={() => {
                                  let allSickness = JSON.parse(
                                    JSON.stringify(sicknessList)
                                  );
                                  allSickness.find(
                                    (s) => s.name == e.name
                                  ).has = !e.has;
                                  setSicknessList(allSickness);
                                }}
                                disabled={!editModeState}
                              ></input>
                              <label className="ml-1">{e.name}</label>
                              {e.name == "Otros" && e.has && (
                                <input
                                  className={formStyle}
                                  type="text"
                                  value={e.other}
                                  onChange={(f) => {
                                    let allSickness = JSON.parse(
                                      JSON.stringify(sicknessList)
                                    );
                                    allSickness.find(
                                      (s) => s.name == e.name
                                    ).other = f.target.value;
                                    setSicknessList(allSickness);
                                  }}
                                  disabled={!editModeState}
                                />
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>

                <div className="px-5 pb-5"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPatient;
