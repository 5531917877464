export const speakText = async (text) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_GOOGLE_TEXT_TO_SPEECH_PROD}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text }),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const audioBlob = await response.blob();
    const audioUrl = URL.createObjectURL(audioBlob);
    const audio = new Audio(audioUrl);

    return new Promise((resolve, reject) => {
      audio.onended = () => {
        resolve(true); // Return true after the audio has finished playing
      };

      audio.onerror = (err) => {
        reject(false); // Return false if there's an error
      };

      audio.play();
    });
  } catch (error) {
    console.error("Error during text-to-speech:", error);
    return false; // Return false if there's an error with the fetch request
  }
};
