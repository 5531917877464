import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./firebase";

const getPatientSerices = async (patientRecordId) => {
  try {
    console.log(patientRecordId);
    const patientServicesCollectionRef = query(
      collection(db, "payments"),
      where("patientId", "==", patientRecordId)
    );
    const querySnapshot = await getDocs(patientServicesCollectionRef);

    if (!querySnapshot.empty) {
      // Process the data
      const services = querySnapshot.docs.map((doc) => doc.data());
      return services;
    } else {
      console.log("No documents found in the 'payments' collection.");
      // Handle this case as needed
    }
  } catch (error) {
    console.error("Error fetching remote payments: ", error);
    throw error;
  }
};

export { getPatientSerices };
