import React, { useEffect, useState } from "react";
import { speakText } from "../../utils/speakText";
import { AIButton } from "./AIButton";
import usePumble from "../../pumble/usePumble";
import { useSelector } from "react-redux";

const AI = ({ setAIDetails, type, financeContent }) => {
  const [isListening, setIsListening] = useState(false);
  const [isAIThinking, setIsAIThinking] = useState(null);
  const [patient, setPatient] = useState(null);
  const [conversation, setConversation] = useState([]);
  const [isAIReplying, setIsAIReplying] = useState(false);
  const user = useSelector((state) => state.user?.userDetails);
  const { sendAIUsage } = usePumble();

  useEffect(() => {
    if (patient) {
      setAIDetails(patient);
    }
  }, [patient]);

  const handleStartListening = () => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognition) {
      alert("Your browser doesn't support Speech Recognition.");
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.interimResults = false;
    recognition.lang = "es-PY"; // Set the language

    let timeoutId;

    recognition.onstart = () => {
      setIsListening(true);
    };

    recognition.onresult = async (event) => {
      const speechText = event.results[0][0].transcript;

      // Clear any existing timeout to reset the listening time
      clearTimeout(timeoutId);

      // Start a new timeout to stop listening after 3 seconds of silence
      timeoutId = setTimeout(() => {
        setIsListening(false); // Stop listening after 3 seconds
        recognition.stop(); // Stop the recognition
      }, 5000); // 5000 milliseconds = 3 seconds

      // Update conversation with user input
      const newConversation = [
        ...conversation,
        { role: "user", content: speechText },
      ];
      setConversation(newConversation);

      setIsAIThinking(true); // Indicate that AI is thinking
      const chatGPTResponse = await sendToChatGPT(newConversation);
      sendAIUsage(speechText, user);
      let canProceed = await speakText(chatGPTResponse.message);
      if (type === "newPatient") {
        if (
          canProceed &&
          chatGPTResponse.message !==
            "Porfavor verificá que los datos esten correctos."
        ) {
          setIsAIThinking(false); // Reset after response
        } else {
          setIsAIThinking(null);
        }
      } else if (type === "finance") {
        setIsAIThinking(null);
      }
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false); // Update listening state when recognition ends
    };

    recognition.start();
  };

  const sendToChatGPT = async (conversation) => {
    let AIContent;
    let AImessage;
    switch (type) {
      case "newPatient":
        AIContent = `You are a personal data assistant called Starky who speaks Paraguayan Spanish. Your task is to collect the following information from the user: name, birthdate, email, and identity number. If any of these details are missing, ask the user to provide the required information. After gathering all necessary details, return only a JSON object with the properties 'name', 'birthdate' (birthday should be return in dd/mm/yyyy), 'email', and 'identityNumber' (identity number and email should not have any empty spaces), filled with the corresponding values provided by the user. Do not include any additional text, explanations, or prompts in your response ONLY if the user has given all required information.`;
        AImessage = [
          {
            role: "system",
            content: AIContent,
          },
          ...conversation,
        ];
        break;
      case "finance":
        AIContent = `You are a financial analyst. Analyze the following financial data with properties such as patientId, payments, id, date, amount, invoice, description, price, hasBeenPaid, balance, patientName, and createdBy. Answer all questions strictly based on the data provided, and if information is missing, respond with "No hay datos suficientes". Respond only in Paraguayan Spanish. You must not invent data, and you must not use asterisks or any unnecessary symbols.`;
        AImessage = [
          {
            role: "system",
            content: AIContent,
          },
          {
            role: "user",
            content: `${financeContent}`,
          },
          ...conversation,
        ];
    }
    debugger;
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: AImessage,
      }),
    });

    const data = await response.json();
    if (type === "newPatient") {
      const content = data.choices[0].message.content;
      if (isValidJson(content)) {
        try {
          const parsedContent = JSON.parse(content);
          const patientDetails = {
            name: parsedContent.name || "N/A",
            birthdate: parsedContent.birthdate || "N/A",
            email: parsedContent.email || "N/A",
            identityNumber: parsedContent.identityNumber || "N/A",
            tutorName: parsedContent.tutorName || undefined,
            tel: parsedContent.tel || undefined,
            nationality: parsedContent.nationality || undefined,
            maritalStatus: parsedContent.maritalStatus || undefined,
            gender: parsedContent.gender || undefined,
            profession: parsedContent.profession || undefined,
            personalAddress: parsedContent.personalAddress || undefined,
            taxName: parsedContent.taxName || undefined,
            ruc: parsedContent.ruc || undefined,
          };
          setPatient(patientDetails);
          return {
            message: "Porfavor verificá que los datos esten correctos.",
            patientDetails: patientDetails,
          };
        } catch (error) {
          console.error("Error parsing response:", error);
          setIsAIThinking(null);
          throw new Error("Invalid response format or content parsing error");
        }
      } else {
        return {
          message: content,
          patientDetails: null,
        };
      }
    } else if (type === "finance") {
      const content = data.choices[0].message.content;
      if (content) {
        return {
          message: content,
        };
      } else {
        return {
          message: "Estoy a disposición si tienes mas preguntas.",
        };
      }
    }
  };

  useEffect(() => {
    if (isAIThinking !== null && !isAIThinking) {
      handleStartListening();
    }
  }, [isAIThinking]);

  return (
    <div>
      <AIButton
        handleStartListening={handleStartListening}
        disabled={isListening || isAIReplying}
        isListening={isListening}
        isAIThinking={isAIThinking}
        isAIReplying={isAIReplying}
        setIsAIThinking={setIsAIThinking}
      />
    </div>
  );
};

// Helper function to check if a string is valid JSON
const isValidJson = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export default AI;
